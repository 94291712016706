<template>
  <v-app>
      <v-row
          class="mb-6 loginform"
          justify="center"
          no-gutters
          ref="form"        
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-col lg="3">

            <v-container v-if="afterinfo" class="login">

                <p>Your account has been reset.</p>                
                <p>Check your e-mails. An access link has been sent.</p>

            </v-container>

            <v-container v-else class="login">
                <h3 class="mb-2">Reset password</h3>
                <p><i>Reset your password by entering your registered email address.</i></p>
                <div v-if="error" style="color:red;margin-bottom: 14px;">Incorrect e-mail address</div>
                <v-form
                      ref="form"                                            
                    >
                      <v-text-field                      
                        v-model="email"
                        :rules="emailRules"
                        label="E-Mail"
                        required                        
                        @keydown.enter="pwreset"
                      ></v-text-field>
                      <v-btn 
                        class="mr-4" 
                        @click="pwreset"
                        :disabled="!valid"
                        >Send and reset</v-btn>
                </v-form>
            </v-container>

          </v-col>
      </v-row>
  </v-app>
</template>

<script>

export default {
  name: 'Reset',
  //props: ['token'],

  components: {
    //AdminPanos,
    //ContentMenu
  },
  data() {
    return {
      contenttype: this.$route.params.type,
      show1: false,
      valid: true,
      lazy: false,
      error: false,
      afterinfo: false,
      email: '',            
      /*
      emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      */
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ]      
    }
  },
  methods: {

      pwreset() {
          
          const formData = new FormData();

          formData.set('email', this.email);
          //formData.set('password', this.password);

          let self = this;
          this.axios.post( this.$config.host+"/pwreset", formData)
          .then(function (response) {

              if(response.data === false) {
                  self.error = true;
              } else {
                console.log(response.status);
                  //self.$router.push({name: 'Login'});
                  self.afterinfo = true;
              }

          })
          .catch(function (error) {
              console.log(error);
              self.error = true;

          });
      }

  },
  computed:{
      user () {
        return this.$store.getters.userdata
      }
  },
  created() {

    /*
    console.log("created - token:");
    console.log(this.token);
    if(this.token) {

      this.login(this.token);

    }
    */

  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.loginform {
  margin-top:5%;
}
.loginform .login {
  display:block;
  padding-top:100px;
  text-align: center;
}
.loginform .loginbutton {
  text-align: center;
}
.colmiddle {
  text-align:center;
  margin-bottom:30px;
}
.v-card a {
  text-decoration:none;
}
</style>
