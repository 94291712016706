<template>
    <v-main id="contact">

      <div        
        class="mb-6 pt-4 pb-4"
        :style="bgStyles"
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >            
              <h1 class="ratitle">
              Service & Kontakt
              </h1>          
              <p>
                
              </p>
              </v-col>          
            </v-row>
        </v-container>
      </div>


      <v-container>
        <v-row
          class="mb-8"
          >
          <v-col
            cols="12"
            md="8" 
            offset-md="2"             
            >                            
              <p>
                Verantwortlich für diese Seiten:
              </p>
              <!--
              <ul>
                <li>
                  the identification and connection of potential partners from academia, industry and further relevant partners in the region 
                </li>
                <li>
                  the organisation and coordination of networking events
                </li>
                <li>
                  the identification of suitable funding instruments as well as support with project proposals.
                </li>
              </ul>
              -->
              <p>
                E-Mail: ...<br>
                Telefon: ...
              </p>
              <!--
              <blockquote>               

                <div
                  v-for="(contact, key) in contacts"
                  :key="key"
                  >
                  <p>{{ contact.name }}: <a :href="'mailto:'+contact.email">{{ contact.email }}</a></p>
                </div>                
                
              </blockquote>              
              -->
          </v-col>
        </v-row>
      </v-container>

      <v-container>
          <v-row class="text-center">

            <v-col 
              cols="12"
              md="12"
              >

            </v-col>      

          </v-row>
      </v-container>

    </v-main>
</template>

<script>

  export default {
    name: 'Contact',

    components: {
        //VueHtml2pdf
    },

    data: () => ({    
        search: '',        
        teams: [],     
        expanded: [],  
        pdfcontent: {}, 
        isExpanded: Boolean,
        contacts: [
          {
            name: 'Dr. Stefanie Regel (MHB)',
            email: 'stefanie.regel@mhb-fontane.de'
          },
          {
            name: 'Dr. Claudia Deutschmann (BTU)',
            email: 'claudia.deutschmann@b-tu.de'
          },
          {
            name: 'Dr. Günter Peine (FGW)',
            email: 'guenter.peine@fgw-brandenburg.de'
          },
          {
            name: 'Tahani Adnan, M.Sc. (IAP)',
            email: 'tahani.adnan@iap.fraunhofer.de'
          },
          {            
            name: 'Dr. Katharina Kasack (IZI-BB)',
            email: 'katharina.kasack@izi-bb.fraunhofer.de'
          },
          {            
            name: 'Dr. Petra Wiedmer (DIfE)',
            email: 'petra.wiedmer@dife.de'
          },
          {            
            name: 'Dr. Frank Pawlitschek (HPI)',
            email: 'Frank.Pawlitschek@hpi.de'
          },
          {            
            name: 'Prof. Dr. Julia Groß (HMU)',
            email: 'julia.gross@health-and-medical-university.de'
          },
          {            
            name: 'Dr. Manfred Gossen (HZG)',
            email: 'manfred.gossen@hereon.de'
          },
          {            
            name: 'Dr. Anna Sojka-Piotrowska (IHP)',
            email: 'sojka@ihp-microelectronics.com'
          },
          {            
            name: 'Juliane Jury (MPI KG)',
            email: 'juliane.jury@mpikg.mpg.de'
          },
          {            
            name: 'Dr. Ellen Zuther (ZKI-PH)',
            email: 'ZutherE@rki.de'
          },
          {            
            name: 'Dr. Carsten Hille (TH Wildau)',
            email: 'carsten.hille@th-wildau.de'
          },
          {            
            name: 'Sascha Thormann (UP)',
            email: 'sascha.thormann@uni-potsdam.de'
          },
          {
            name: 'Lena Krippner (HC)',
            email: 'lena.krippner@uni-potsdam.de'
          }
        ]      
    }),

    methods: {
      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      }
    },
    computed: {      
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
            width:'50%'
          },
          { 
            text: 'Institution', 
            value: 'partner',
            width:'50%'
          },          
          { 
            text: '', 
            value: 'data-table-expand' 
          }
        ]
      },      
    },
    mounted() {
      console.log("mount List / Home");      
    }

  }
</script>

<style scoped>

.item {
    margin:60px 80px;
}
.item h2 {
    font-size:2.2rem;
}
.item p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.item table {
  width: 100%;
}
.item table td {
  border:1px solid lightblue;
  background-color: yellow;
}
.logo img {
  border:1px solid #eee;  
  max-width: 100%;
}

</style>

<style>

#contact ul {
  margin:0 0 34px 15px;
}
#contact ul li {
  padding:0 10px;
}
#contact blockquote {  
  margin-left:50px;
  padding-top:6px;
}
#contact blockquote > div::after {    
  content: " ";
  display:block;
  height:0;
  clear:both;
}

#contact blockquote > div > p {  
  line-height:1.5;
  width:100%;
  margin: 0 0 4px;
}

#contact blockquote > div > p > a {  
  display: block;
  float: right;
  width:50%;
}

.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;  
}
.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content > td {
  border:0;
}
.researchtable > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}

</style>