<template>
  <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"            
        :preview-modal="false"
        :paginate-elements-by-height="1122"
        filename="A Research Atlas"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"                
        @beforeDownload="beforeDownload($event)"                                
        ref="html2Pdf"
        >
        <section slot="pdf-content" class="pagewrap">

          <section class="pdfsection">
            <div class="top">
              <div v-if="team.partner && team.partner[0].logo_src">                
                <img :src="require('@/logo/' + team.partner[0].logo_src)" height="70" style="float:right" />
              </div>
              <div v-else-if="team.logo_src">
                  <img :src="require('@/logo/' + team.logo_src)" height="70" style="float:right" />
              </div>
              <img v-else src="@/images/logo_thwi.png" height="70" style="float:right" />     
              <h1 class="mytitle" style="padding-top:20px">{{ team.name }}</h1>
            </div>
          </section>

          <section class="pdfsection lightgrey">
            <div>
            <h2 class="mytitle">
            RESEARCH FOCUS & METHODOLOGICAL EXPERTISE
            </h2>          
            <ul class="mylist">
              <li 
                v-for="(foc,m) in team.focus"
                :key="m"
                >
                {{ foc.name }}
                </li>
            </ul>
            </div>
          </section>

          <section class="pdfsection keywords">
            <div>
              <h2 class="mytitle">Keywords</h2>          
              <p
                v-if="team.keywords !== 'undefined' && team.keywords !== 'null'"
                >{{team.keywords }}</p>                              
                <p v-else></p>
              </div>
          </section>

          <section class="pdfsection content">
            <div>
              <div class="image-right">
                <img :src="team.pathcontentimg" v-if="team.contentsrc" />                
                <span class="caption" v-if="team.contentcaption">{{ team.contentcaption}}</span>
              </div>
              <h2 class="mytitle">Research Resources</h2>                                  
              <ul class="mylist" style="margin-right:44%;">
                <li 
                  v-for="(res,m) in team.resources"
                  :key="m"
                  >
                  {{ res.name }}
                  </li>
              </ul>
            </div>
          </section>
          
          <section class="pdfsection network">
            <div v-if="team.network !== 'undefined' && team.network !== 'null'">
              <h2 class="mytitle">Networks & Membership</h2>          
              <p>{{team.network }}</p>                                              
              </div>
          </section>          

          <section class="pdfsection lightblue">
            <div>
              <h2 class="mytitle">Contact & Further Information</h2> 
              <div class="images-left" style="float:left;">      
                <div class="wrap">
                  <img :src="team.paththumb" class="portrait_print" v-if="team.paththumb" />
                  <img src="@/images/profile-placeholder-image-png.png" v-else />              
                </div>
              </div>
              
              <div style="padding-top:20px;">
                <div style="margin-left:40%;" class="content pt-10" v-html='team.contact' v-if="team.contact!='null'" />
              </div>
              <div style="clear:both;height:0;"></div>
            </div>
          </section>

        </section>

      </vue-html2pdf>

  </div>
</template>

<script>

  //import jspdf from 'jspdf';
  import VueHtml2pdf from 'vue-html2pdf';

  export default {
    name: 'PDF_file',

    props: [],

    components: {
        VueHtml2pdf
    },

    data: () => ({    
        pdfcontent: {},
        team: {}
    }),

    methods: {
      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      },
      generatePdfProfile (content) {
          this.pdfcontent = content;
          //this.pdfcontent.contact = this.convertLineBreak(content.contact),
          this.team = content;
          this.$refs.html2Pdf.generatePdf();
      },
      async beforeDownload ({ html2pdf, options, pdfContent }) {
          
            const downloadPdf = (pdf) => {              
                let link = document.createElement('a');
                link.target = '_blank';
                link.href = pdf.output('bloburl');
                link.download = 'myFileName.pdf';          
                //link.click();
                window.open(link.href, '_blank');
                link.remove();
            };

            await html2pdf().from(pdfContent)
              .set(options)
              .toPdf()
              .get('pdf')
              .then((pdf) => downloadPdf(pdf));
          
          /*
          await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
              
              window.open(pdf.output('bloburl'), '_blank');

          //}).save('dateiname')
          });
          */        
      }      
    },
    computed: {      

      user () {       
        //console.log(this.$store.getters.userdata)
        return this.$store.getters.userdata
      }

    }

  }
</script>

<style>

.pagewrap {
  height:1122px;
  position: relative;  
}

.images-left > .wrap {
  width:190px;
  height:190px;
  border-radius: 50%;  
  margin:0;
  padding:0;
  position:relative;
  overflow: hidden;
}
.portrait,
.portrait_print {
  height:100%;
  width:auto;    
  margin-left: 50%;
  transform: translateX(-50%);
}
.images-right {
  float:right;
  width: 44%;  
}
.images-left {
  float:left;
  width: 56%;
  margin:30px 0 25px 20px;  
}
ul.mylist {
  padding-right:50px;
  padding-left:20px;
}
ul.mylist li {
  padding-left:10px;
  padding-bottom:4px;
}  
ul.mylist li::marker {
  color:#D22B2B;    
}
.pdfsection {
    margin:0px 0px 20px;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.pdfsection > div {
    padding:0 80px;
}
.pdfsection.lightgrey {
    background-color: #f8f8f8;    
    min-height:180px;
}
.pdfsection.lightgrey > div {
  padding-top:26px;
  padding-bottom:26px;
}
.pdfsection.content {
  /*height:352px*/
  height:262px;    
  position:relative;  
}
.pdfsection.keywords {  
  height: 66px;  
}
.pdfsection.content .image-right {
  float:right;
  width:38%;   
  height:254px;    
}
.pdfsection .image-right img {
  max-width:100%;
  max-height:100%;
  width:auto;
  height:auto;
  margin:0;
  padding:0;
}
.pdfsection .image-right .caption {
  display:block;
  font-size:.58rem;
}
.pdfsection.lightblue {
  position: absolute;
  width:100%;
  bottom:0;
  left:0;
  background-color: #d7e3f1;    
  margin-bottom:0;
  padding-top:20px;  
  height:280px;
}
.pdfsection .top {
  padding-top:40px;
  min-height:120px;
}
.pdfsection h1 {
  font-size:1.3rem;
  margin-right:260px;  
}
.pdfsection h2 {
    font-size:.86rem;
    font-weight: 600;
    color:#444;
}
.pdfsection ul li {
  font-size:.84rem;
  color:#444;
}
.mylist.top li::marker {
  color:#D22B2B;    
}  
.pdfsection p {
    margin-top: .2rem;
    margin-bottom: 1rem;
    color:#444;
    font-size:.84rem;
}
.pdfsection .content {
    font-size:.84rem;
}
.pdfsection .images-left {
  float:left; 
  margin:14px 0 25px 30px;  
}
.pdfsection table {
  width: 100%;
}
.testpdf table td {
  border:1px solid lightblue;
  background-color: yellow;
}


</style>