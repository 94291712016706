<template>
  <v-app>
      <v-row
          class="mb-6 loginform"
          justify="center"
          no-gutters
        >
          <v-col lg="3">

            <v-container v-if="user.name" justify="center" class="loginbutton">
                <v-form
                      ref="form"
                      align="center"
                    >
                      <v-btn class="" @click="logout">Logout</v-btn>
                </v-form>
            </v-container>

            <v-container v-else-if="afterinfo" class="login">

                <p>Thank you for registering with Brandenburg Health Sciences.</p>                
                <p>Check your e-mails and confirm your registration by clicking on the link - as described in the e-mail.</p>

            </v-container>

            <v-container v-else class="login">
                <h3 class="mb-2">Register</h3>
                <p><i>Register with your institution's e-mail address.</i></p>
                <div v-if="error" style="color:red;margin-bottom: 14px;">Incorrect e-mail address</div>
                <v-form
                      ref="form"                                            
                    >
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        label="E-Mail"
                        required
                        @keydown.enter="register"
                      ></v-text-field>
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[pwrules.required]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        @keydown.enter="registe"
                        @click:append="show1 = !show1"
                      ></v-text-field>                      
                      <v-btn class="mr-4" @click="register">Register</v-btn>
                </v-form>
            </v-container>

          </v-col>
      </v-row>
  </v-app>
</template>

<script>
//import AdminPanos from './content/AdminPanos';
//import ContentMenu from './navigation/ContentMenu';

export default {
  name: 'Login',
  props: ['token'],

  components: {
    //AdminPanos,
    //ContentMenu
  },
  data() {
    return {
      contenttype: this.$route.params.type,
      show1: false,
      error: false,
      afterinfo: false,
      email: '',
      password: '',
      pwrules: {
          required: value => !!value || 'Required',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
      },
      emailRules: [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]

    }
  },
  methods: {

      login(token) {

          const formData = new FormData();

          formData.set('username', 'guest');          
          formData.set('password', token);          

          let self = this;
          this.axios.post( this.$config.host+"/userreg", formData)
          .then(function (response) {

              console.log("Antwort auf userreg + token");

              if(response.data === false) {
                  self.error = true;
              } else {
                  self.$router.push({name: 'Profile'});
              }

          })
          .catch(function (error) {
              console.log(error);
              self.error = true;

          });

      },

      register() {

          const formData = new FormData();

          formData.set('email', this.email);
          formData.set('password', this.password);

          let self = this;
          this.axios.post( this.$config.host+"/register", formData)
          .then(function (response) {

              if(response.data === false) {
                  self.error = true;
              } else {
                console.log(response.status);
                  //self.$router.push({name: 'Login'});
                  self.afterinfo = true;
              }

          })
          .catch(function (error) {
              console.log(error);
              self.error = true;

          });
      },
      logout() {

          let self = this;
          this.axios.get( this.$config.host+"/logout")
          .then(function (response) {

              if(response.data === false) {
                  self.error = true;
              } else {
                  self.$store.commit('clearUser');
                  self.$router.push({name: 'Home'});
              }

          })
          .catch(function (error) {
              console.log(error);

          });

      }

  },
  computed:{
      user () {
        return this.$store.getters.userdata
      }
  },
  created() {

    console.log("created - token:");
    console.log(this.token);
    if(this.token) {

      this.login(this.token);

    }

  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.loginform {
  margin-top:5%;
}
.loginform .login {
  display:block;
  padding-top:100px;
  text-align: center;
}
.loginform .loginbutton {
  text-align: center;
}
.colmiddle {
  text-align:center;
  margin-bottom:30px;
}
.v-card a {
  text-decoration:none;
}
</style>
