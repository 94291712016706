export default class access {

  enter = {};
  read = {};
  delete = {};  

  constructor(ud) {

    this.ud = ud;

    /**
     * ud: user logged in
     * ud.type : i.e. "admin, editor"
     * ud.team : i.e. "vinnlab, icampus"
     */     

    var type = ud ? ud.type : null;

    this.enter = {

      admin: type ? true : false

    }

    this.read = {

      adminmenu: this.readAdminmenu(type) || false,

      editormenu: this.readEditormenu(type) || false,

      guestmenu: this.readGuestmenu(type) || false,

      //pano: this.readPano(type) || false,

      //user: this.readUser(type) || false,

    }

    /*
    this.add = {

      pano: this.addPano(type) || false,

      user: this.addUser(type) || false,

      link: this.addLink(type) || false,

    }


    this.delete = {

      poi: this.deletePoi(type) || false,

      pano: this.deletePano(type) || false
    
    }

    */

  }    

  /**
   * 
   * @param {*} itemObj: item object
   * @param {*} type : type string
   * @param {*} user : user object
   */
  readitem ( itemObj, type, user ) {
    
    switch (type) {

      case 'pano':

        if(user.type == 'admin') {

          return true;

        } else {

          return itemObj.team_name === user.team ? true : false;

        }      

      case 'category':

        if(user.type == 'admin') {

          return true;

        } else {

          //console.log(itemObj.team_name);        
          
          return itemObj.team_name !== 'iCampus' ? true : false;

        }      
          

      default:
        break;

    }    
    
  }
  
  readAdminmenu( e ) {

    return ['admin'].includes(e) ? true : false 

  }      

  readEditormenu( e ) {

    return ['admin', 'editor'].includes(e) ? true : false 

  }        

  readGuestmenu( e ) {

    return ['admin', 'editor', 'guest'].includes(e) ? true : false 

  }        
  
  /*
  readUser( e ) {

    return e == "admin" ? true : false

  }  

  readLink( e ) {

    //return e == "admin" ? true : false
    return ['admin', 'innohub'].includes(e) ? true : false 

  }    

  addPano( e ) {

    return ['admin','innohub'].includes(e) ? true : false 

  }    

  addUser( e ) {

    return e == "admin" ? true : false

  }   

  deletePano( e ) {

    return e ? true : false        

  }  
  */

}
