import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false

//import home from "./components/home.vue";
import formular from "./components/formular.vue";
import list from "./components/list.vue";
import users from "./components/users.vue";
import partners from "./components/partners.vue";
import login from "./components/login.vue";
import register from "./components/register.vue";
import pwreset from "./components/pwreset.vue";
import profile from "./components/profile.vue";
import node from "./components/node.vue";
import contact from "./components/contact.vue";
import about from "./components/about.vue";
import store from './helper/store.js';

import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_KEY });
Vue.use(VueRouter);
Vue.use(VueAxios, axios);

axios.defaults.withCredentials = true;

store.commit('clearUser');
var $path = process.env.VUE_APP_PATH || '';

Vue.prototype.$config = {  
  host: process.env.VUE_APP_BACKEND_HOST || 'http://localhost:3003',
  path: $path
};

//console.log(process.env);

const router = new VueRouter({
  mode: 'history',  
  routes: [    
    {
      path: $path,
      component: list,
      name: "Home"
    },
    {
      path: $path+"formular",
      component: formular,
      name: "Submit data"
    },
    {
      path: $path+"contact",
      component: contact,
      name: "Contact"
    },    
    {
      path: $path+"about",
      component: about,
      name: "About"
    },
    {
      path: $path+"list",
      component: list,
      name: "List"
    },   
    {
      path: $path+"list/:userid",
      component: list,
      name: "MyList",
      props: (route) => ({         
        userid: parseInt(route.params.userid),        
      })    
    },   
    {
      path: $path+"users",
      component: users,
      name: "Users"
    },               
    {
      path: $path+"partners",
      component: partners,
      name: "Partners"
    },                   
    {
      path: $path+"register",
      component: register,
      name: "Register"
    },           
    {
      path: $path+"pwreset",
      component: pwreset,
      name: "Reset"
    },               
    {
      path: $path+"login",
      component: login,
      name: "Login"
    },       
    {
      path: $path+"profile",
      component: profile,
      name: "Profile"
    },           
    {
      path: $path+"node/:nodeid",
      component: node,
      name: "Node",
      props: (route) => ({         
        query: parseInt(route.params.nodeid),
        message: route.params.message        
      })    
    },
    {
      path: $path+"userreg/:token",
      component: register,
      name: "Register",
      props: (route) => ({         
        token: route.params.token,        
      })    
    },    
    {
      path: $path+"node/:nodeid/edit",
      component: formular,
      name: "Edit data",
      props: (route) => ({         
        query: parseInt(route.params.nodeid),        
      })    
    }    
  ],
  scrollBehavior() {
        return {x: 0, y: 0}
    }
})
/*
router.beforeEach((to, from, next) => {
  document.title = `${ process.env.VUE_APP_TITLE } - ${ to.name }`;  
  next();
})
*/

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
