<template>
  <v-app>
      <v-row
          class="mb-6"
          justify="center"
          no-gutters
        >
          <v-col lg="4">

            <div class="profile" v-if="user.name">

              <v-form>

                <v-container class="loginbutton mb-0 pb-1">
                  <h2>Profile: {{ user.name }}</h2>
                </v-container>

                <v-container
                  class="mx-0 my-0 px-0 py-0"
                  style="text-align:left"
                  >
                    <v-btn               
                      color="#778899"
                      class="ma-2 mb-8 ml-3 white--text"                    
                      small
                      depressed
                      @click="togglePWChange()"                      
                      >
                      Change password
                      <v-icon
                        right                        
                        dark
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                </v-container>

                <v-container justify="center" class="colmiddle">

                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>
                            <v-text-field
                              class="mx-0 my-0 px-0 py-0 blancsize"                              
                              v-model="user.name"                                                           
                              :append-outer-icon="show1icon ? 'mdi-content-save-outline' : ''"                              
                              @focus="show1icon = true"
                              @blur="show1icon = false"
                              dense
                              flat                              
                              solo
                              @click:append="nameSubmit()"
                              @change="nameSubmit()"
                            >
                            </v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td>E-Mail</td>
                          <td>{{ user.email }}</td>
                        </tr>
                        <tr>
                          <td>Rolle</td>
                          <td>{{ user.type }}</td>
                        </tr>        
                        <tr>
                          <td>Institution</td>
                          <td>{{ user.partner_name }}</td>
                        </tr>
                        <!--
                        <tr>
                          <td>Team</td>
                          <td>{{ user.team_name }}</td>
                        </tr>        
                        -->
                      </tbody>
                    </template>
                  </v-simple-table>

                </v-container>

                <!-- Change password -->
                <v-container
                  v-if="pwchange"
                  >
                  <v-row
                    class="bgprofile"
                    >
                    <v-col cols="12"                      
                      >
                      <v-text-field
                        v-model="password"
                        label="Password"
                        prepend-icon="mdi-lock-outline"
                        type="password"
                        :rules="[required, min6]"
                        :counter="6"
                        >
                        <template v-slot:append>
                          <v-icon
                            v-if="successPass"
                            color="green"
                            >{{ passRule }}</v-icon
                            >
                          <v-icon
                            v-if="!successPass"
                            color="red"
                            >{{ passRule }}</v-icon
                            >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <!-- password1 -->
                      <v-text-field
                        v-model="password1"
                        label="Verify Password"
                        prepend-icon="mdi-lock-outline"
                        type="password"
                        :rules="[required, min6, matchingPasswords ]"
                        :counter="6"
                        >
                        <template v-slot:append>
                          <v-icon
                            v-if="successPass1"
                            color="green"
                            >{{ passRule1 }}</v-icon
                          >
                          <v-icon
                            v-if="!successPass1"
                            color="red"
                            >{{ passRule1 }}</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>

                  </v-row>

                  <!--                
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[pwrules.required]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"                                      
                    ></v-text-field>

                    -->

                  <v-alert
                    v-if="thisalert"
                    dense
                    text
                    type="success"
                  >
                    Password saved
                  </v-alert>

                  <v-text-field
                    v-model="user.email"
                    type="hidden"
                    class="hidden"
                  ></v-text-field>

                  <v-btn class="mr-4"
                  :disabled="matchingPasswords() !== true"
                  @click="pwSubmit"                
                  >Send/Save</v-btn>                   
              
                </v-container>

              </v-form>

            </div>

            <div class="profile" v-else>
              <p>No permission</p>
            </div>

          </v-col>
      </v-row>
  </v-app>
</template>

<script>
//import AdminPanos from './content/AdminPanos';
//import ContentMenu from './navigation/ContentMenu';

export default {
  name: 'Login',
  props: ['passRule','passRule1'],

  components: {
    //AdminPanos,
    //ContentMenu
  },
  data() {
    return {
      contenttype: this.$route.params.type,
      show1: false,
      error: false,
      thisalert: false,
      password: '',
      password1: '',
      successPass: false,
      successPass1: false,
      pwchange: false,
      show1icon: false,
      show2icon: false,
      pwrules: {
          required: value => !!value || 'Required',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
      },
    }
  },
  methods: {

    required: function(value) {
      if (value) {
        return true;
      } else {
        return 'This field is required.';
      }
    },
    min6: function(value) {
      if (value.length >= 6) {
        return true;
      } else {
        return 'Password should have more than 6 characters.';
      }
    },
    matchingPasswords: function() {
      if (this.password === this.password1) {
        return true;
      } else {
        return 'Passwords does not match.';
      }
    },

    togglePWChange: function() {
      this.pwchange = !this.pwchange;
    },

    setAlert: function() {
      var self = this;
      setTimeout(() => {

        self.thisalert = false;
        window.location.reload();
        
      }, 4000);
    },

    pwSubmit() {

        const formData = new FormData();
        formData.set('email', this.user.email);
        formData.set('password', this.password);

        let self = this;
        this.axios.post( this.$config.host+"/profile", formData)
        .then(function (response) {

          console.log(response);
          //self.$router.push({name: 'Profile'});                
          //self.$router.go(self.$router.currentRoute)
          self.thisalert = true;
          self.setAlert();          

        })
        .catch(function (error) {
            console.log(error);
            self.error = true;

        });
    },

    nameSubmit() {

        const formData = new FormData();

        formData.set('email', this.user.email);
        formData.set('name', this.user.name);

        let self = this;
        this.axios.post( this.$config.host+"/profile/name", formData)
        .then(function (response) {

          console.log("response set name");
          console.log(response);                              
          self.$store.commit('setUser', self.user);

        })
        .catch(function (error) {
            console.log(error);
            self.error = true;

        });
    },



  },
  computed:{

    user () {      
      //console.log("computed user");
      //console.log(this.$store.getters.userdata);
      return this.$store.getters.userdata
    },

  },

  watch: {

    user() {
      //console.log("watch user");
      return this.user
    },

    passRule () {
      if (this.password === '') {
        // field is empty
        this.successPass = false;
        return '';
      } else if (this.min6(this.password) === true) {
        // password ok
        this.successPass = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass = false;
        return 'mdi-close';
      }
    },
    passRule1() {
      if (this.password1 === '') {
        // field is empty
        this.successPass1 = false;
        return '';
      } else if (this.min6(this.password1) === true && this.matchingPasswords() === true) {
        // password ok
        this.successPass1 = true;
        return 'mdi-check';
      } else {
        // password wrong
        this.successPass1 = false;
        return 'mdi-close';
      }
    }      

  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.profile {
  display:block;
  padding-top:120px;
  text-align: center;
}
.loginbutton {
  text-align:left;
}
.bgprofile {
  background-color: #f9f9f9;
}
.blancsize .v-input__slot {  
  font-size: 0.875rem;
  margin-bottom:0 !important;
  padding:0 2px !important;
}
.blancsize .v-text-field__details {
  display:none !important;
}
.blancsize .v-input__icon.v-input__icon--append {
  min-width: 20px;
  width:20px;
}
.blancsize .v-input__icon.v-input__icon--append .v-icon {
  font-size:20px; 
  opacity:0.5;
}

.hidden {
  visibility: hidden;
}
.colmiddle {
  text-align:left;
  margin-bottom:10px;
}
.colmiddle p {
  margin:0;
}
.v-card a {
  text-decoration:none;
}
</style>
