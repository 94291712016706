<template>
    <v-main 
      v-if="team.published || user.name"
      >            

      <div        
        class="mb-2 pt-2 pb-0"       
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                offset-md="1"                
              >       
                <v-container v-if="message" class="mx-0 px-0">
                  <v-alert                  
                    dense
                    text
                    type="success"
                    dismissible
                    :value="thisalert"
                  >
                    <div v-html="message"></div>
                  </v-alert>                                        
                </v-container>       
                <v-container v-if="localmessage" class="mx-0 px-0">
                  <v-alert                  
                    dense
                    text
                    type="success"
                    dismissible
                    :value="thisalertlocal"
                  >
                    <div v-html="localmessage"></div>
                  </v-alert>                                        
                </v-container>       
                
                <v-container  class="mx-0 px-0">
                  <div 
                    :class="classes()" 
                    align="right" 
                    style="float:right;width:100%;"
                    >

                    <div
                      class="status submit"
                      style="float:left;"
                      >
                      <span><i>Not submitted yet</i></span>                      
                    </div>                    
                    <div
                      class="status publish"
                      style="float:left;"
                      >
                      <span><i>Not published yet</i></span>
                    </div>     

                    <v-tooltip max-width="340" left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="generateReport(team)" 
                        size="30"
                        color="gray"
                        class="px-0 py-0 mr-6 my-1"
                        >
                        mdi-file-pdf-box
                      </v-icon>    
                    </template>
                    <span>Get PDF from profile</span>
                    </v-tooltip>                    

                    
                    <v-tooltip max-width="340" left 
                      v-if="team.submitted && nodeValidate()"
                      >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon                                
                          v-bind="attrs"
                          v-on="on"              
                          size="24"
                          color="gray"
                          class="px-0 py-0 mr-6 my-1"                      
                          >
                          mdi-check
                        </v-icon>                       
                    </template>
                    <span>Profile submitted</span>
                    </v-tooltip>                                           
                    
                    <v-tooltip max-width="340" left
                      v-else-if="nodeValidate()"
                      >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon                  
                          @click="submitTeam(team.id, true)"
                          v-bind="attrs"
                          v-on="on"
                          size="20"
                          color="gray"
                          class="px-0 py-0 mr-6 my-1"                    
                          >
                          mdi-upload
                        </v-icon>                    
                    </template>                    
                    <span>Profile not submitted yet</span>
                    </v-tooltip>                 

                    <v-icon     
                      @click="deleteTeam(query)"           
                      v-if="nodeValidate()"
                      size="20"
                      color="red"
                      class="px-0 py-0 mr-6 my-1"
                      >
                      mdi-delete
                    </v-icon>    
                    
                    <router-link :to="$config.path+'node/' + query + '/edit'" text small v-if="user.name">
                      <v-icon                
                        size="20"
                        color="red"
                        class="px-0 py-0 mr-0 my-1"
                        >                        
                        mdi-pencil-outline                        
                      </v-icon>    
                    
                    </router-link>                            

                  </div>
                </v-container>

                <div class="head">                          
                  <div class="headtitle">                         
                  <span class="location" style="font-size:.9rem">
                  Profile
                  </span>          
                  <h1 class="mytitle">
                  {{ team.name }}
                  </h1>          
                  </div>
                  <div class="logo" v-if="logo_src !== null">                                           
                    <img :src="require(`@/logo/${logo_src}`)" alt="Logo" title="Title" class="img" />              
                  </div> 
                </div>

              </v-col>          
            </v-row>
        </v-container>
      </div>

      <div        
        class="mb-4 pt-0 pb-6"        
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                offset-md="1"
              >            
              <h2 class="mytitle top">
              ANWENDUNGSFELD
              </h2>          
              <div v-if="team['category']">
              {{ team.category[0].name }}
              </div>
              </v-col>          
            </v-row>
        </v-container>
      </div>


      <div        
        class="mb-4 pt-4 pb-6"
        :style="bgStyles"
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                offset-md="1"
              >            
              <h2 class="mytitle top">
              TECHNOLOGIE
              </h2>          
              <ul class="mylist">
                <li 
                  v-for="(foc,m) in team.focus"
                  :key="m"
                  >
                  {{ foc.name }}
                  </li>
              </ul>
              </v-col>          
            </v-row>
        </v-container>
      </div>
    
      <!--
      <v-container>
        <v-row
          class="mb-0"
          >
          <v-col
            cols="12"
            md="10" 
            offset-md="1"             
            >    
            <h2 class="mytitle">Keywords</h2>                      
              <p
                v-if="team.keywords !== 'undefined' && team.keywords !== 'null'"
                >{{team.keywords }}</p>                              
                <p v-else></p>               
          </v-col>
        </v-row>
      </v-container>      
      -->

      <v-container>
        <v-row
          class="mb-0"
          >
          <v-col
            cols="12"
            md="10" 
            offset-md="1"             
            >    
            <h2 class="mytitle">AUFGABEN</h2>                                  
            <ul class="mylist" style="margin-right:44%;">
              <li 
                v-for="(res,m) in team.resources"
                :key="m"
                >
                {{ res.name }}
                </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>

      <!--
      <v-container>
        <v-row
          class="mb-6"
          >
          <v-col
            cols="12"
            md="10" 
            offset-md="1"             
            >    
            <h2 class="mytitle">Network & Memberships</h2>                                  
            <p>{{ team.network }}</p>
          </v-col>
        </v-row>
      </v-container>
      -->

      <div
      style="background-color:#d7e3f1"
      >
      <v-container>
        <v-row
          class="mb-0 pt-4"
          >
          <v-col
            cols="12"
            md="10" 
            offset-md="1"             
            >    
            <h2 class="mytitle">POSTER</h2>             
            <div class="wrap">
              <a :href="team.pathpdf" alt="" target="_blank">{{ team.pathpdf }}</a>              
            </div>                                  
          </v-col>
        </v-row>
      </v-container>
      </div>      

      <v-container>
        <v-row
          class="mb-2"
          >
          <v-col
            cols="12"
            md="8" 
            offset-md="2"             
            >      

            <pdfprofile               
                ref="html2Pdf"              
            ></pdfprofile>

          </v-col>
        </v-row>
      </v-container>

    </v-main>
    <v-main v-else>
      <v-container>
          <v-row>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >            
            <p>            
            You do not have permission to access this page.
            </p> 
            </v-col>          
          </v-row>
      </v-container>
    </v-main>
</template>

<script>

  //import jspdf from 'jspdf';
  
  import pdfprofile from "./pdfprofile.vue";

  export default {
    name: 'Node',    
    props: ['query','message'],

    components: {
        pdfprofile
    },

    data: () => ({        
        team: [],
        logo_src: null,
        thisalert: true,
        thisalertlocal: true,
        localmessage: null
    }),

    methods: {

      classes() {
        let classes = 'control';
        if(this.team.submitted == false) {
          classes += ' notsubmitted';
        } else if(this.team.published == false) {
          classes += ' notpublished'
        }
        return classes;
      },

      nodeValidate() {      
        if(         
          ( typeof(this.user.id) !== 'undefined' && !this.user.team_id && !this.team.id ) ||
          (this.user.type === 'editor' && this.team.partner_id === this.user.partner_id) ||
          ( typeof(this.user.id) !== 'undefined' && this.user.id === this.team.owner ) ||
          (!this.user.team_id && !this.team.id && this.user.type === 'editor') ||          
          (this.user.type === 'editor' && !this.team.id) ||
          (this.user.type === 'admin')
          ) {            
          return true
        } else {          
          return false
        }      
      },
      URLify(string){
        var urls = string.match(/(((ftp|https?):\/\/)[-\w@:%_+.~#?,&//=]+)/g);
        var urlLabel;
        if (urls) {
          urls.forEach(function (url) {
            urlLabel = url.replace(/^https?:\/\//, '');
            string = string.replace(url, '<a target="_blank" href="' + url + '">' + urlLabel + "</a>");
          });
        }
        return string.replace("(", "<br/>(");
      },
      generateReport (team) {
          //this.$refs.html2Pdf.generatePdf()
          this.$refs.html2Pdf.generatePdfProfile(team)          
      },
      getLogo(team) {
        if(team.partner && team.partner.length > 0 && team.partner[0].src) {    
          team.logo_src = team.partner[0].src;
          return team.partner[0].src
        }
      },
      getTeam() {
        this.axios.get( this.$config.host+"/team/"+this.query ).then(response => (
          this.team = response.data, 
          this.team.contact = this.convertLineBreak(this.team.contact),
          this.team.contact = this.URLify(this.team.contact),                    
          this.logo_src = this.getLogo(this.team) 
        ));
      },
      convertLineBreak(text) {        
          return text.replace(/\r\n/g, '<br />')        
      },      
      convertSpace(text) {        
        return text.replace(/\n/g, '<br />')        
      },

      submitTeam ( id, bool ) {
          const formData = new FormData();
          formData.set('id', id);
          formData.set('published', bool);
          let self = this;        

          const confirm = window.confirm('Submit profile?')    
          if (confirm) {

            this.axios.post( this.$config.host+"/team/submit", formData)          
              .then(function (result) {         
                
                if(result && result.data) {  

                    self.team.submitted = true;      

                }

                self.localmessage = 'The profile has been submitted.<br>The responsible person at your institution will release the data as soon as possible.';

              })

          }
      },

      deleteTeam( id ) {
        let self = this;
        const confirm = window.confirm('Are you sure?')    
        if (confirm) {
          this.axios.post( this.$config.host+"/team/delete/"+id )
          .then(function () {                            
              self.$router.push({path: self.$config.path});              
          })
          .catch(function (error) {
              self.output = error;
          });                
        }    
      }
    },
    computed: {      
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      user () {
        return this.$store.getters.userdata
      }
    },
    mounted() {      
      this.getTeam();      
    },
    created(){         
      //setTimeout(()=>{
      //  this.thisalert=false
      //},5000)
    }

  }
</script>

<style scoped>

.status {
  display: none;
}
.status > span {
  display:inline-block;
  padding:6px 10px;
  color:#999;
} 
.notpublished {
  background-color: rgba(110, 180, 214, 0.2);
  border-radius: 4px;
  margin-bottom:10px;
}
.notsubmitted {
  background-color: rgba(214, 144, 110, 0.2);
  border-radius: 4px;
  margin-bottom:10px;
}
.notpublished .status.publish,
.notsubmitted .status.submit {
  display: inline;
}
.controls {  
  margin-bottom:20px;
}
.head {
  clear:both;
}
.logo {
  display:inline-block;
  margin-left:100px;
}
.logo .img {
  height:110px;
  width: auto;
}
.headtitle {
  display:inline-block;
  vertical-align: top;
  padding-top:14px;
  min-width:300px;
  max-width:500px;
}  
.content {
  white-space: auto;
}

.images-right > span {
  display: block;
  padding:0;
  margin:4px 0;
  font-size:.7rem;
}

</style>
