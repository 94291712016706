<template>
  <v-app>
      <v-row
          class="mb-6 loginform"
          justify="center"
          no-gutters
        >
          <v-col lg="3">

            <v-container justify="center" class="colmiddle login"  v-if="user.name">
              <h3>User data</h3>
              <p>Name: {{ user.name }}<br>                            
              Rolle: {{ user.type }} </p>
            </v-container>

            <v-container v-if="user.name" justify="center" class="loginbutton">
                <v-form
                      ref="form"
                      align="center"
                    >
                      <v-btn class="" @click="logout">Logout</v-btn>
                </v-form>
            </v-container>

            <v-container v-else class="login">
                <h3 class="mb-2">Login</h3>
                <p>Log in with the access data you received for this site. Usually the username is your e-mail address.</p>
                <div v-if="error" style="color:red;margin-bottom: 14px;">Incorrect login data</div>
                <v-form
                      ref="form"                                            
                    >
                      <v-text-field
                        v-model="username"
                        :rules="[v => !!v || 'Username required']"
                        label="Username (e-mail address)"
                        required
                        @keydown.enter="login"
                      ></v-text-field>
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[pwrules.required]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Password"
                        @keydown.enter="login"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                      <p><i><router-link :to="{ name: 'Register'}">Not registered yet?</router-link></i></p>
                      <p><i><router-link :to="{ name: 'Reset'}">Forgotten password?</router-link></i></p>
                      
                      <v-btn class="mr-4" @click="login">Login</v-btn>
                </v-form>
            </v-container>

          </v-col>
      </v-row>
  </v-app>
</template>

<script>
//import AdminPanos from './content/AdminPanos';
//import ContentMenu from './navigation/ContentMenu';

export default {
  name: 'Login',

  components: {
    //AdminPanos,
    //ContentMenu
  },
  data() {
    return {
      contenttype: this.$route.params.type,
      show1: false,
      error: false,
      username: '',
      password: '',
      pwrules: {
          required: value => !!value || 'Required',
          min: v => v.length >= 8 || 'Min 8 characters',
          emailMatch: () => ('The email and password you entered don\'t match'),
      }
    }
  },
  methods: {

      login() {

          const formData = new FormData();

          formData.set('username', this.username);
          formData.set('password', this.password);

          let self = this;
          this.axios.post( this.$config.host+"/login", formData)
          .then(function (response) {

              if(response.data === false) {
                  self.error = true;
              } else {
                  self.$router.push({name: 'Profile'});
              }

          })
          .catch(function () {
              //console.log(error);
              self.error = true;

          });
      },
      logout() {

          let self = this;
          this.axios.get( this.$config.host+"/logout")
          .then(function (response) {

              if(response.data === false) {
                  self.error = true;
              } else {
                  self.$store.commit('clearUser');
                  self.$router.push({name: 'Home'});
              }

          })
          .catch(function (error) {
              console.log(error);

          });

      }

  },
  computed:{
      user () {
        return this.$store.getters.userdata
      }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.loginform {
  margin-top:5%;
}
.loginform .login {
  display:block;
  padding-top:100px;
  text-align: center;
}
.loginform .loginbutton {
  text-align: center;
}
.colmiddle {
  text-align:center;
  margin-bottom:30px;
}
.v-card a {
  text-decoration:none;
}
</style>
