<template>
    <v-main>

      <div        
        class="mb-6 pt-4 pb-4"
        :style="bgStyles"
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >            
              <h1 class="ratitle">
              About us
              </h1>          
              <p>            
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              </p>              
              </v-col>          
            </v-row>
        </v-container>
      </div>


      <v-container>
        <v-row
          class="mb-8"
          >
          <v-col
            cols="12"
            md="8" 
            offset-md="2"             
            >                            
              <p>            
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
              </p>              
          </v-col>
        </v-row>
      </v-container>

      <v-container>
          <v-row class="text-center">

            <v-col 
              cols="12"
              md="12"
              >
            ...
            </v-col>      

          </v-row>
      </v-container>

    </v-main>
</template>

<script>

  export default {
    name: 'About',

    components: {
        //VueHtml2pdf
    },

    data: () => ({    
        search: '',        
        teams: [],     
        expanded: [],  
        pdfcontent: {}, 
        isExpanded: Boolean
    }),

    methods: {
      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      }
    },
    computed: {      
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
            width:'50%'
          },
          { 
            text: 'Institution', 
            value: 'partner',
            width:'50%'
          },          
          { 
            text: '', 
            value: 'data-table-expand' 
          }
        ]
      },      
    },
    mounted() {
      console.log("mount List / Home");      
    }

  }
</script>

<style scoped>

.item {
    margin:60px 80px;
}
.item h2 {
    font-size:2.2rem;
}
.item p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.item table {
  width: 100%;
}
.item table td {
  border:1px solid lightblue;
  background-color: yellow;
}
.logo img {
  border:1px solid #eee;  
  max-width: 100%;
}

</style>

<style>

.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;  
}
.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content > td {
  border:0;
}
.researchtable > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}

</style>