<template>
    <v-main>

      <div        
        class="mb-6 pt-4 pb-4"
        :style="bgStyles"
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >            
              <h1 class="ratitle">
              AI Research Atlas - Verwaltung
              </h1>          
              <p> </p> 
              </v-col>          
            </v-row>
        </v-container>
      </div>
      <!--
      user.type {{ user.type }}<br>
      user.id {{ user.id }}<br>
      node partner id {{ node.partner_id }}<br>
      user partner id {{ user.partner_id }}<br>
      team_id: {{ user.team_id }}<br>
      node.id: {{ node.id }}<br>
      node owner: {{ node.owner }}<br>
      -->
      <v-form         
        v-if="nodeValidate()"
        ref="form"        
        v-model="valid"
        :lazy-validation="lazy"
        >
        <v-container>
          <v-row
            class="mb-2"
            >
            
            <!-- message -->
            <v-col
              cols="12"
              md="8" 
              offset-md="2"             
              >
              <p>
                <b>Please use the following mask to enter your research profile into the research atlas.</b><br>
                (Text in English please; check uploaded images for image rights beforehand)
                </p>             
            </v-col>

            <!-- button & title -->
            <v-col
              cols="12"
              md="8" 
              offset-md="2"             
              >      
              
              <div style="text-align:right;margin-bottom:30px">

                <v-btn class="mr-4"
                  v-if="node.id"
                  :disabled="!valid"
                  @click="formSubmit"
                  small
                  depressed                  
                  >Update</v-btn>

                  <v-btn class="mr-0"
                  v-else
                  :disabled="!valid"
                  small                  
                  @click="formSubmit"
                  >Save</v-btn>                                                      
                                
                <router-link 
                  v-if="node.id"
                  :to="{ name: 'Node', params: { nodeid: node.id }}"
                  >
                  <v-icon                       
                    size="22"
                    color="gray"
                    align="right"
                    class="px-0 py-0 mr-4"                  
                    >
                    mdi-eye-outline
                  </v-icon>    
                </router-link>

                <v-icon     
                @click="deleteTeam(query)"  
                v-if="node.id"        
                size="22"
                color="red"
                align="right"
                class="px-0 py-0 mr-2"
                >
                mdi-delete
                </v-icon>    

              </div>
              
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >RESEARCH GROUP *</p>
                </template>
                <span>Name of the research group</span>
              </v-tooltip>                    
              <v-text-field
                v-model="node.name"
                :rules="nameRules"
                clearable
                outlined
                placeholder="Name of the research group"                
                required
                background-color="#f7f7f7"             
              ></v-text-field>
            </v-col>

            <!-- institution -->
            <v-col
              cols="12"
              md="8" 
              offset-md="2"             
              >                          
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >INSTITUTION *</p>
                </template>
                <span>Primary affiliation</span>
              </v-tooltip>                            
              <v-autocomplete
                  v-model="node.partner_id"
                  :items="partners"                       
                  item-value="id"
                  item-text="name"                  
                  placeholder="Select institution"
                  class="mt-0 mb-0 py-2"
                  background-color="#f7f7f7"                       
                  outlined
                  :rules="inputRule"                  
                  >
                  <template v-slot:item='{item}'> <div v-html='item.name'/></template>
                  <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
              </v-autocomplete>                    
            </v-col>

            <!-- old: subject classification / category -->
            <!--
            <v-col
              cols="12"
              md="8" 
              offset-md="2"             
              >                          
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >ANWENDUNGSFELD *</p>
                </template>
                <span>Anwendungsfeld</span>
              </v-tooltip>                            
              <v-autocomplete
                  v-model="node.category_id"
                  :items="categories"                       
                  item-value="id"
                  item-text="name"                  
                  placeholder="Select subject"
                  class="mt-0 mb-0 py-2"
                  background-color="#f7f7f7"   
                  :rules="inputRule"                              
                  outlined
                  >
                  <template v-slot:item='{item}'> <div v-html='item.name'/></template>
                  <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
              </v-autocomplete>                    
            </v-col>
            -->
            

            <!-- description -->
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              class="hiddenfield"              
              >              
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >DESCRIPTION</p>
                </template>
                <span>Description</span>
              </v-tooltip>              
               <v-textarea                                
                background-color="#f7f7f7"             
                v-model="node.description"
                filled                
                auto-grow                
                rows="4"
                row-height="30"                                
                placeholder="Description research group"                
                clearable
                outlined
              ></v-textarea>
            </v-col>

            <!-- TECHNOLOGIEANSÄTZE ODER -METHODEN title -->
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              >
              <p class="float-right">
                <span :class="fontcolor['resources']">{{ characterLength['resources'] }}/{{ characterLimit['resources'] }}</span>
              </p>
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >TECHNOLOGIEANSÄTZE ODER -METHODEN</p>
                </template>
                <span>TECHNOLOGIEANSÄTZE ODER -METHODEN</span>
              </v-tooltip>
            </v-col>

            <!-- TECHNOLOGIEANSÄTZE ODER -METHODEN -->
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              v-for="(resource, ii) in node.resources"
              :key="'b'+ii"     
              >              
            <!--
              <v-text-field                            
              :class="fontcolor['resources'] + ' mt-0 pt-0'"
              v-model="resource.name"          
              placeholder="Technologieansatz oder -methode"
              prepend-icon="mdi-circle-medium"
              append-outer-icon="mdi-close"
              filled
              dense
              @click:append-outer="remove('resources', ii)"
              @input="countNode(node.resources, 'resources')" 
              ></v-text-field>
              -->

              <v-autocomplete
                  v-model="resource.name"
                  :items="technologies"                       
                  item-value="id"
                  item-text="name"                  
                  placeholder="Technologieansatz oder -methode"
                  prepend-icon="mdi-circle-medium"
                  append-outer-icon="mdi-close"
                  class="mt-0 mb-0 py-2"
                  background-color="#f7f7f7"                                              
                  dense
                  filled
                  @click:append-outer="remove('resources', ii)"
                  >
                  <template v-slot:item='{item}'> <div v-html='item.name'/></template>
                  <template v-slot:selection='{item}'> <div v-html='item.name'/></template>
              </v-autocomplete>                    

            </v-col>

            <!-- add TECHNOLOGIEANSATZ ODER -METHODE -->
            <v-col
              cols="12"
              md="8"
              class="px-12 mb-12"     
              offset-md="2"
              align="right"
              >

              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  v-bind="attrs"
                  v-on="on"
                  @click="add('resources')" 
                  class="lighten-3"              
                  small
                  depressed
                  width="auto"
                  color="grey"
                  >Technologieansatz hinzufügen</v-btn>
                </template>
                <span>Technologieansatz hinzufügen</span>
              </v-tooltip>

            </v-col>     


            <!-- ANWENDUNGSFELDER Titel -->
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              >
              <p class="float-right">
                <span :class="fontcolor['focus']">{{ characterLength['focus'] }}/{{ characterLimit['focus'] }}</span>
              </p>

              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >ANWENDUNGSFELDER</p>
                </template>                
                <span>ANWENDUNGSFELDER</span>
              </v-tooltip>    
              
            </v-col>

            <!-- ANWENDUNGSFELDER -->           
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              v-for="(focus, i) in node.focus"
              :key="'a'+i"
              >              
              <v-text-field              
              class="m-0 p-0"
              v-model="focus.name"                              
              placeholder="Anwendungsfall"
              prepend-icon="mdi-circle-medium"
              append-outer-icon="mdi-close"              
              filled
              dense
              @click:append-outer="remove('focus', i)"             
              @input="countNode(node.focus, 'focus')" 
              ></v-text-field>

            </v-col>

            <!-- add ANWENDUNGSFALL -->
            <v-col
              cols="12"
              md="8"
              class="px-12 mb-12"              
              offset-md="2"              
              align="right"            
              >

              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                <v-btn 
                  v-bind="attrs"
                  v-on="on"
                  @click="add('focus')" 
                  class="lighten-3"              
                  small
                  width="auto"
                  color="grey"
                  depressed
                  >Anwendungsfall hinzufügen</v-btn>
                </template>                
                <span>Einen Anwendungsfall pro Textfeld. Insgesamt sind maximal 6 Einträge möglich.</span>
              </v-tooltip>    

            </v-col>     

            <!-- Keywords / EINORDNUNG -->            
            <v-col
              cols="12"
              md="8" 
              offset-md="2"             
              >                          
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >EINORDNUNG</p>
                </template>
                <span>Bis zu 5 Keywords, mit Komma getrennt (,)</span>
              </v-tooltip>                    
              <v-text-field
                v-model="node.keywords"             
                :rules="keywordRules"
                clearable
                outlined
                placeholder="keywords"                
                required
                background-color="#f7f7f7"             
              ></v-text-field>
            </v-col>            
    
            <!-- Poster PDF -->
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              class="mb-8"
              >              
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >POSTER PDF</p>
                </template>
                <span>Poster PDF - max. 2 MB</span>
              </v-tooltip>         

              <div class="imgwrap">          
                  <v-icon                       
                    size="22"                    
                    align="right"
                    class="px-0 py-0 mr-4"                  
                    style="float:right"
                    @click="node.src = null;previewsrc = null"
                    >
                    mdi-close
                  </v-icon>    
                <div class="filetoolarge" v-if="fileTooLarge" v-html="'File too large'"></div>
                <label>
                  <input 
                    type="file" 
                    id="file" 
                    ref="file" 
                    accept="application/pdf"
                    v-on:change="onChangeFileUpload('file')" 
                    />
                </label>
                <v-text-field
                  v-model="node.src"
                  type="hidden"
                ></v-text-field>
                <div class="previewwrap">                  
                  <a :href="previewsrc" target="_blank" ref="preview" v-if="previewsrc" alt="Preview Image" >
                  {{ previewsrc }}
                  </a>
                </div>
              </div>

            </v-col>

            <!-- networks & memberships -->
            <!--
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              >              
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >NETWORKS & MEMBERSHIPS</p>
                </template>
                <span>Networks & memberships</span>
              </v-tooltip>         
               <v-textarea                
                background-color="#f0efef"
                v-model="node.network"
                :rules="networkRules"
                filled                
                auto-grow                
                rows="4"
                row-height="30"                                
                placeholder="Networks & memberships"
                clearable
                outlined
              ></v-textarea>
            </v-col>
            -->

            <!-- contact & information --> 
            <!--           
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              >              
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >CONTACT & FURTHER INFORMATION</p>
                </template>
                <span>Contact & further information</span>
              </v-tooltip>         
               <v-textarea                
                background-color="#f0efef"
                v-model="node.contact"
                filled                
                auto-grow                
                rows="4"
                row-height="30"                                
                placeholder="Contact & further information (e.g. Phone, Email, Websites, ORCID, ResearchGate)"
                clearable
                outlined
              ></v-textarea>

            </v-col>
            -->

            <!-- content image -->
            <!--
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              style="visibility:hidden"
              >              
              <v-tooltip max-width="340" right>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    v-bind="attrs"
                    v-on="on"
                    style="display:inline-block"
                    >CONTENT IMAGE</p>
                </template>
                <span>Content image - max. 1.5 MB</span>
              </v-tooltip>         

              <div class="contentimgwrap">          
                  <v-icon                       
                    size="22"                    
                    align="right"
                    class="px-0 py-0 mr-4"                  
                    style="float:right"
                    @click="node.contentsrc = null;contentpreviewsrc = null"
                    >
                    mdi-close
                  </v-icon>    
                <div class="filetoolarge" v-if="contentfileTooLarge" v-html="'File too large'"></div>
                <label>
                  <input 
                    type="file" 
                    id="contentfile" 
                    ref="contentfile" 
                    accept="image/jpeg, image/png"
                    v-on:change="onChangeFileUpload('contentfile')" 
                    />
                </label>
                <v-text-field
                  v-model="node.contentsrc"
                  type="hidden"
                ></v-text-field>
                <div class="previewwrap" v-if="contentpreviewsrc">
                  <img class="preview" ref="contentpreview" :src="contentpreviewsrc" alt="Preview Image" />
                  <v-text-field
                      v-model="node.contentcaption"
                      clearable
                      :rules="maxrule"
                      counter="100"
                      outlined
                      placeholder="Image Caption"                
                      required
                      background-color="#f7f7f7"             
                    ></v-text-field>
                </div>
              </div>              

            </v-col>
            -->

          </v-row>

        </v-container>

        <!-- Submit / Update -->
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              align="center"
              class="mb-14"
              >
                <v-text-field
                  v-if="node.owner"
                  v-model="node.owner"
                  type="hidden"
                  class="hidden"
                ></v-text-field>                

                <v-text-field
                  v-model="node.id"
                  type="hidden"
                  class="hidden"
                ></v-text-field>

                <v-btn class="mr-4"
                v-if="node.id"
                :disabled="!valid"
                @click="formSubmit"
                >Update</v-btn>

                <v-btn class="mr-4"
                v-else
                :disabled="!valid"
                @click="formSubmit"                
                >Save</v-btn>
              
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-container
        v-else
        >
          <v-row>
            <v-col
              cols="12"
              md="8"
              offset-md="2"
            >            
            <p>            
            You do not have permission to access this page.
            </p> 
            </v-col>          
          </v-row>
      </v-container>
      <!--
      <HelloWorld/>
      -->
    </v-main>
</template>

<script>

  export default {
    name: 'Formular',

    props: ['query','condenseHeader'],

    data: () => ({        
      maxrule: [v => v.length <= 100 || 'Max 100 characters'],
      localhost: false,
      template: null,
      testbed: false,
      file: null,
      contentfile: null,
      previewsrc: null,
      contentpreviewsrc: null,
      fileTooLarge: false,
      contentfileTooLarge: false,      
      countWarning: {
        focus: false,
        resources: false
      },
      characterLength: {
        focus: 0,
        resources: 0
      },
      characterLimit: {
        focus: 360,
        resources: 360
      },
      fontcolor: {
        focus: 'colorblack',
        resources: 'colorblack'
      },
      controls: {
        visible: true,
      },
      activeBtn: 1,

      valid: true,
      lazy: false,
      teamname: '',
      description: '',
      lastname: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 200) || 'Name must be less than 10 characters'
      ],
      keywordRules: [        
        v => (v && v.split(",").length < 6 ) || 'Keywords must be less than 5'        
      ],      
      networkRules: [        
        v => (v && v.length < 250 ) || 'Text must be less than 250 characters'        
      ],            
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],    
      inputRule: [
        v => {
          if(v && v > 0) {
            return true
          } else {
            return "Please select an item"
          }
        }
      ],  
      node: {
        focus: [{          
          name: ''
        }],
        resources: [{          
          name: ''
        }]            
      },    
      partners: [],
      categories: [],
      autosave: true,
      technologies: [
        {
          name: 'Datenmanagement & -analyse'
        },
        {
          name: 'Sensorik & Kommunikation'
        },
        {
          name: 'Mensch-Maschine-Interaktion'
        },
        {
          name: 'Assistenzsysteme'
        },
        {
          name: 'Bilderkennung & Interpretation'
        },
        {
          name: 'Robotik & autonome Systeme'
        },
        {
          name: 'Sprach- & Textverstehen'
        },
        {
          name: 'Virtuelle & erweiterte Realität'
        },
        {
          name: 'Geistes-, sozial-, rechts, wirtschaftswissenschaftliche Forschung zu KI'
        },
        {
          name: 'Hardware für KI-Beschleunigung'
        },
        {
          name: 'Maschinelles Lernen'
        }        
      ]
    }),

    methods: {

      nodeValidate() {

        if(
          //(!this.user.team_id && !this.node.id) || (user.id == node.owner)
          ( typeof(this.user.id) !== 'undefined' && !this.user.team_id && !this.node.id ) ||
          (this.user.type === 'editor' && this.node.partner_id === this.user.partner_id) ||
          ( typeof(this.user.id) !== 'undefined' && this.user.id === this.node.owner ) ||
          (!this.user.team_id && !this.node.id && this.user.type === 'editor') ||          
          (this.user.type === 'editor' && !this.node.id) ||
          (this.user.type === 'admin')
          ) {            
          return true
        } else {          
          return false
        }        
      },
      async recaptcha() {                
        // (optional) Wait until recaptcha has been loaded.
        //await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        //const token = await this.$recaptcha('login')        

        // Do stuff with the received token.
        //console.log(token);
        return;
      },
      getPartners() {        
        this.axios.get( this.$config.host+"/partners" ).then(response => (
          this.partners = response.data,
          this.partners.sort((a, b) => a.name > b.name ? 1 : -1)          
        ));
      },
      getCategories() {        
        this.axios.get( this.$config.host+"/categories" ).then(response => (
          this.categories = response.data,          
          this.categories.sort((a, b) => a.name > b.name ? 1 : -1)
        ));
      },      
      getTeam() {        
        this.axios.get( this.$config.host+"/team/"+this.query ).then(response => (
          this.node = response.data,          
          this.setNode(this.node),
          console.log("THIS TEAM"),
          console.log(this.node)
        ));
      },
      setNode(node) {
        if(node.focus == null) {
          node.focus = [{name:''}];          
        } 
        if(node.resources == null) {
          node.resources = [{name:''}];
        }
        if(node.src) {          
          this.previewsrc = node.pathpdf;
        }
        if(node.contentsrc) {          
          this.contentpreviewsrc = node.pathcontentthumb;
        }        
      },
      countNode(node, cat) {        
        var self = this; 
        var character = 0;
        node.forEach(function(category){           
          character = character + category.name.length;          
        });
        self.characterLength[cat] = character;
        this.countWarning[cat] = (self.characterLength[cat] > self.characterLimit[cat]) ? true : false;
        this.fontcolor[cat] = this.countWarning[cat] ? 'colorred' : 'colorblack';
      },
      add ( category ) {                
          if(this.node[category].length < 6) {
            this.node[category].push({           
              value: '',           
            })
          }        
      },
      remove (category, index) {
          this.node[category].splice(index, 1);
          this.countNode(this.node[category], category);
      },
      removeImg (ref) {                        

        this.$refs[ref].value = null;

      },
      /**
       * 
       */
      readURL(file,cat) {
        console.log("readURL");
        console.log(file);
          var self = this;
          var preview = cat == 'file' ? "previewsrc" : "contentpreviewsrc";
          if (file) {
              //var reader = new FileReader();
              //reader.onload = function (e) {
              //    self[preview] = e.target.result;
              //}
              //reader.readAsDataURL(file);
              self[preview] = file.name;
          } else {
            this[cat] = self[preview];
          }
      },                
      onChangeFileUpload(ref){
        this.fileTooLarge = false;
        this.contentfileTooLarge = false;
        this[ref] = this.$refs[ref].files[0];
        var mb = (this[ref].size / (1024*1024));
        if ( mb > 16 ) {                
          if(ref === 'file') {
            this.fileTooLarge = true;    
          } else {
            this.contentfileTooLarge = true;    
          }          
          this.$refs[ref].value = null;
        }                
      },      
      validate () {
          this.$refs.form.validate()
      },      
      startAutosave () {

        let self = this;
        if(self.autosave) {
          setTimeout(() => {            
            self.autoSave();            
          }, 8000);
          self.autosave = false;
        }

      },

      autoSave() {          
          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          
          const formData = new FormData();
          formData.set('id', this.node.id ? this.node.id : null);
          formData.set('id_tmp', this.node.id_tmp ? this.node.id_tmp : null);
          formData.set('name', this.node.name ? this.node.name : ' ');
          formData.set('keywords', this.node.keywords ? this.node.keywords : ' ' );
          formData.set('partner_id', this.node.partner_id);
          formData.set('category_id', this.node.category_id);          
          formData.set('description', this.node.description ? this.node.description : ' ');          
          formData.set('network', this.node.network ? this.node.network : ' ');
          formData.set('contact', this.node.contact ? this.node.contact : ' ');
          formData.set('process', this.node.id || this.node.id_tmp ? 'update' : 'generate');
          formData.set('ldap_id', this.user.ldap_id ? this.user.ldap_id : this.user.email);
          formData.set('owner', this.node.owner ? this.node.owner : null);          
          formData.set('src', this.node.src ? this.node.src : null);
          formData.set('contentsrc', this.node.contentsrc ? this.node.contentsrc : null);
          formData.append('focus', JSON.stringify(this.node.focus));
          formData.append('resources', JSON.stringify(this.node.resources));
          formData.set('contentcaption', this.node.contentcaption ? this.node.contentcaption : ' ');

          let self = this;

          if(self.autosave) {

            this.axios.post( this.$config.host+"/team", formData, config)
            .then(function (result) {   

                if(self.node.id || self.node.id_tmp) { 
                  console.log("ID exists");
                } else {
                  self.node.id_tmp = result.data.id;
                }

                console.log("AUTOSAVE");
                self.autosave = true;

            })
            .catch(function (error) {
                self.output = error;
            });

          }

      },           

      formSubmit() {          
          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          
          const formData = new FormData();
          formData.set('id', this.node.id ? this.node.id : null);
          formData.set('name', this.node.name);
          formData.set('keywords', this.node.keywords);
          formData.set('partner_id', this.node.partner_id);
          formData.set('category_id', this.node.category_id);          
          formData.set('description', this.node.description ? this.node.description : ' ');          
          formData.set('network', this.node.network ? this.node.network : ' ');
          formData.set('contact', this.node.contact ? this.node.contact : ' ');
          formData.set('process', (this.node.id || this.node.id_tmp) ? 'update' : 'generate');
          formData.set('ldap_id', this.user.ldap_id ? this.user.ldap_id : this.user.email);
          formData.set('owner', this.node.owner ? this.node.owner : null);          
          formData.set('src', this.node.src ? this.node.src : null);
          formData.set('contentsrc', this.node.contentsrc ? this.node.contentsrc : null);
          formData.append('focus', JSON.stringify(this.node.focus));
          formData.append('resources', JSON.stringify(this.node.resources));
          formData.append('file', this.file);
          formData.append('contentfile', this.contentfile);
          formData.set('contentcaption', this.node.contentcaption);

          let self = this;
          self.autosave = false;

          this.axios.post( this.$config.host+"/team", formData, config)
          .then(function (result) {              
              let message = 'The profile has been saved.';
              if(self.node.id) { message = 'Dataset successfully updated.' }
              if(result.data.id) {
                self.$router.push({ 
                  name: 'Node', 
                  params: {                     
                    nodeid: result.data.id,
                    message: message 
                  } 
                })
              } else {                                
                self.$router.push({path: self.$config.path});                
              }                            
          })
          .catch(function (error) {
              self.output = error;
          });
      },  
      deleteTeam( id ) {
        let self = this;
        const confirm = window.confirm('Are you sure?')    
        if (confirm) {
          this.axios.post( this.$config.host+"/team/delete/"+id )
          .then(function () {
              self.$router.push({path: self.$config.path});
          })
          .catch(function (error) {
              self.output = error;
          });                
        }    
      }          
    },
    watch: {
      node: {        
        handler () {
          this.startAutosave();
        },
        deep: true        
      }
    },
    computed: {
      bgStyles() {
        return {
          "background-color": "#f0efef"
        };
      },
      user () {        
        return this.$store.getters.userdata
      }      
    },
    mounted() {
      this.getPartners();   
      this.getCategories();   
      if(this.query) {
        this.getTeam();   
      }      
    }    

  }
</script>

<style scoped>

.colorblack {
  color: #666;
}
.colorred {
  color: #ff0000;
}
.hidden {
  visibility: hidden;
}
.hiddenfield {
  visibility: hidden;
  height:10px;
}
.filetoolarge {
  color:#ff0000;
}
.testpdf {
    margin:60px 80px;
}
.testpdf h2 {
    font-size:2.2rem;
}
.testpdf p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.testpdf table {
  width: 100%;
}
.testpdf table td {
  border:1px solid lightblue;
  background-color: yellow;
}
.imgwrap,
.contentimgwrap {
  background: rgba(0, 0, 0, 0.04);
  padding:30px 20px 20px;
}

.previewwrap img {
  max-width:100%;
}

</style>
<style>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: rgba(0, 0, 0, 0.04);
}
.v-input--is-focused .v-input__slot { 
  background-color: rgba(250, 250, 210, 0.3) !important;  
}
.colorred > .v-input__control > .v-input__slot {  
  background-color: rgba(253, 237, 236, 0.8) !important;
}

</style>